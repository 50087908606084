@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');

.menu-wrapper{
  width: 20%;
  position: relative;
  left: 0px;
  z-index: 999;
}
.navbarmenu-wrapper{
  float: left;
  width: 100%;
  background: #FFFFFF;
  border: 0.5px solid #000000;
  padding: 32px 22px 22px 22px;
  margin: 2.3rem 0rem;
  z-index: 9;
}
.menubottomoptions{
  float: left;
  width: 100%;
  top: 18.1rem;
  margin-left: 0px;
}

.menubottomoptions ul{
  list-style: none;
  width: 100%;
  padding: 18px 15px;
  background: #ffffff;
  border: 0.5px solid #000000;
  margin: 0 0 14px 0;
}
.menubottomoptions ul li{
  width: 100%;
  text-align: right;
}
.menubottomoptions ul li a{
  color: #000;
  font-size: 15px;
  line-height: 16.95px;
  text-decoration: none;
  letter-spacing: -0.01em;
}
.logoimage{
  width: 100%;
  text-align: left;
  margin: 0 0 40px 0;
}
div.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div.navcontainer {
  width: 100%;
}

#dropdownsmenu {
  border: 0;
  border-radius: 0px;
  background: #000000;
  color: #ffffff;
  width: 100%;
  height: 24px;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: -0.01em;
  position: relative;
  padding: 0 0.5rem 0 0.3rem;
  text-align: right;
  font-family: 'Space Mono', monospace;
}

#dropdownsmenu:hover {
  cursor: pointer;
}

#dropdownsmenu::after {
  content: "";
  height: 15px;
  width: 15px;
  background: url('../img/select-arrow.svg') no-repeat;
  background-position: left center;
  transform: rotate(-90deg);
  float: left;
  position: relative;
  top: 0.5px;
  transition: all 0.5s ease;
}

#dropdownsmenu.expanded::after {
  top: 1px;
  left: 0px;
  transform: rotate(0deg);
}

ul.options {
  border: 1px solid #ffffff;
  display: none;
  list-style: none;
  padding: 4px 0 0 0;
  margin: 0;
  text-align: right;
  transition: all 0.8s ease;
}

ul.show {
  display: block;
  transition: all 0.8s ease;
}

ul.options li {
  padding: 1px 10px;
}

ul.options li:active,
ul.options li:focus,
ul.options li:hover,
ul.options li[aria-selected="true"] {
  background: #ffffff;
  cursor: pointer;
  text-decoration: line-through;
  font-family: 'Space Mono', monospace;
}

ul.options li a{
  color: #000000;
  font-weight: 400;
  font-size: 15px;
  line-height: 16.95px;
  letter-spacing: -0.01em;
  text-decoration: none;
  font-family: 'Space Mono', monospace;
}

ul.options li a:hover{
  color: #000000;
  text-decoration: none;
  font-family: 'Space Mono', monospace;
}

#customenuoptions li:nth-child(4){
border-top: 0.5px solid #000000;
padding-top: 4px;
margin: 4px 0 0px 0;
}

#customenuoptions li:nth-child(7){
  border-bottom: 0.5px solid #000000;
  padding-bottom: 6px;
  margin: 0px 0 4px 0;
}
.eventslink{
  background: #151515;
  font-size: 15px;
  line-height: 16.95px;
  color: #ffffff !important;
  height: 24px;
  padding: 0px 27px 0 10px;
  text-align: right !important;
  width: 103px;
  position: relative;
  font-family: 'Space Mono', monospace;
}
.eventslink::after{
  content: '';
  background: url('../img/arrow-right.svg')no-repeat;
  width: 11px;  
  height: 11.6px;
  position: absolute;
  top: 7px;
  right: 10px;
}

.downloadLinks{
  background: #151515;
  font-size: 15px;
  line-height: 16.95px;
  color: #ffffff !important;
  height: 24px;
  padding: 0px 30px 0 10px;
  text-align: right !important;
  width: 153px;
  position: relative;
  margin-bottom: 8px;
  float:right;
}
.downloadLinks span{
  position: relative;
  top: 3px;
  font-family: 'Space Mono', monospace;
}
.downloadLinks::after{
  content: '';
  background: url('../img/arrow-down.svg')no-repeat;
  width: 12.16px;
  height: 13.64px;
  position: absolute;
  top: 5.5px;
  right: 10px;
}
.downloadmobile{
  font-size: 12px !important;
  font-weight: 400 !important;
  text-align: right !important;
  letter-spacing: -0.01em !important;
  color: #000000 !important;
  position: relative;
  top: 3px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}
.eventslistul h4{
  font-size: 13px;
  font-weight: 700;
  line-height: 15.6px;
  margin-bottom: 7px;
  color: #000000;
  letter-spacing: -0.01em;
  font-family: 'Plus Jakarta Sans', sans-serif;
}
.eventslistul .eventslistulactive{
  font-size: 13px !important;
  line-height: 15.6px !important;
  letter-spacing: -0.01em;
  color: #000000;
  font-weight: 300;
  font-style: italic;
  margin-bottom: 20px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}
.eventslistul li a span{
  position: relative;
  top: 3px;
}
.eventslistul li{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.downloadspace{
  width: 100%;
  text-align: right;
  padding-left: 15px;
}
.downloadspace::after{
  content: '';
  float: right;
  background: url('../../components/img/menu-download.png') no-repeat;
  width: 24px;
  height: 35px;
  position: relative;
  right: -36px;
  top: -40px;
}
.downloadreader{
  padding: 18px 15px 15px 15px !important;
  margin: 0 0 12px 0 !important;
}

.downloadNotes{
  width: 100%;
  margin-bottom: 0px;
  font-size: 12px;
  clear: both;
  white-space: nowrap;
}

.downloadNotes a{
  font-size: 12px !important;
  text-decoration: underline !important;

}
/*responsive css*/
@media (min-width: 1281px) and (max-width: 1366px) {}
@media (min-width: 1024px) and (max-width: 1280px) {}
@media (min-width: 768px) and (max-width: 1023px) {}
/*mobile vertical layout*/
@media only screen and (max-width: 767px) {
  .menu-wrapper{
    width: 100%;
    margin: 0;
    left: 0;
    z-index: 9;
  }
  .navbarmenu-wrapper{
    width: 100%;
    margin: 1.2rem 0.3rem;
  }
  .home-wrapper{
    padding-top: 30px;
  }
  .App{
    overflow: hidden;
  }
  ul.options li {
    padding: 10px 10px;
}
  #customenuoptions li:nth-child(4) {
    border-top: 0.5px solid #000000;
    padding-top: 15px;
    margin: 5px 0 0px 0;
}
#customenuoptions li:nth-child(7) {
  border-bottom: 0.5px solid #000000;
  padding-bottom: 15px;
  margin: 0px 0 5px 0;
}
.eventslistul,
.menubottomtwinimg,
.downloadreader{
  display: none;
}
}