.home-wrapper{
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  /*background: #fff;*/
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.homeimagesgrid .gridimgmask{
  position: relative;
  display: flex;
}
.gridimgmask p{
  padding: 0 0 0 10px;
  margin: 0;
}

.homeimagesgrid {
  flex-direction: column;
  display: flex;
}
.firstlist{
  height: 70vh !important; 
  align-items: center;
  justify-content: space-between;
}

.secoundlist{
  height: 100vh !important;
  align-items: flex-start;
  justify-content: flex-end;
}
/* .thirdlist .gridimgmask:first-child{
  position:absolute;
  top: 0;
}
.thirdlist .gridimgmask:nth-child(2){
  position: relative;
  top: 6rem;
  left: 3rem;
  z-index: 9;
} */


.fourthlist{
  position: relative;
  height: 100vh;
}

.fourthlist .gridimgmask:first-child{
  position: relative;
  top: 2rem;
  left: 0.3rem;
}
.fourthlist .gridimgmask:nth-child(2){
  position: relative;
    top: 4rem;
    left: -8rem;
}

.fourthlist .gridimgmask:last-child{
  position: relative;
  top: 2.5rem;
  left: 8.9rem;
}

.fifthlist .gridimgmask:first-child{
  position: relative;
  top: -1.9rem;
  right: 1.4rem;
}


.overlayeffect{
  position: relative;
}
.overlayeffect img{
  display: block;
  width: 100%;
  height: auto;
}
.overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: #000000;
  overflow: hidden;
  width: 100%;
  height:0;
  /*transition: .5s ease;*/
}

.overlayeffect:hover .overlay {
  bottom: 0;
  height: 100%;
  cursor: pointer;
}

.text {
  font-weight: 600;
  font-size: 14px;
  line-height: 15.42px;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  padding: 12px;
  transform: translate(-50%, -50%);
  text-align: left;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Plus Jakarta Sans', sans-serif;
}
.text p{
  padding: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 15.82px;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  font-family: 'Plus Jakarta Sans', sans-serif;
}
/* .menubottomoptions{
  display: none;
} */
.firstlist .gridimgmask {
  position: relative;
  display: flex;
  top: 6.5rem;
  left: 2rem;
}

.secoundlist .gridimgmask:first-child{
  position: relative;
  top: -1.2rem;
  right: -7rem;
  z-index: 1;
}

.secoundlist .gridimgmask:nth-child(2){
  position: relative;
  top: -2.6rem;
  left: -7.7rem;
  right: inherit;
}
.secoundlist .gridimgmask:last-child{
  position: relative;
  right: -5rem;
}
.thirdlist .gridimgmask:first-child{
  position: relative;
  top: 0.5rem;
  left: 6rem;
}
.thirdlist .gridimgmask:last-child{
  position: relative;
  top: 14.8rem;
  left: 2.7rem;
}

.homeimagesgrid.thirdlist{
display: flex;
height: 100vh;
align-items: flex-start;
justify-content: flex-start;
}
.homeimagesgrid.fourthlist{
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.homeimagesgrid.fifthlist{
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width:767px) {
  
  .gridimgmask p{
    padding: 0px;
  }

}

@media only screen and (max-width:490px) {
  .homeimagesgrid .gridimgmask{
    flex-direction: column;
  }
}